<template>
  <q-form ref="editForm">
    <c-card title="LBLBASEINFO" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn
            v-show="editable"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="performanceResultRecord"
            :mappingType="saveType"
            label="LBLSAVE"
            icon="save"
            @beforeAction="savePerformanceResultRecord"
            @btnCallback="savePerformanceResultRecordCallback" 
          />
          <c-btn
            v-show="editable&&isOld"
            label="LBLREMOVE"
            icon="delete_forever"
            @btnClicked="deleteperformanceResultRecord"
          />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-text
            required
            :editable="editable"
            label="실기결과기록부명"
            name="recordName"
            v-model="performanceResultRecord.recordName">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-text 
            :editable="editable"
            label="관계인" 
            name="relatedUserName" 
            v-model="performanceResultRecord.relatedUserName" 
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-text 
            :editable="editable"
            label="관계인 주소" 
            name="address" 
            v-model="performanceResultRecord.address" 
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant 
            required
            :editable="editable"
            type="edit" 
            name="plantCd" 
            v-model="performanceResultRecord.plantCd" />
        </div>
      </template>
    </c-card>
    <div class="row q-mt-sm">
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <c-card title="대상물" class="cardClassDetailForm">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-text
                required
                :editable="editable"
                label="상호"
                name="building"
                v-model="performanceResultRecord.building">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
              <c-text 
                :editable="editable"
                label="소재지" 
                name="location" 
                v-model="performanceResultRecord.location" 
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-radio 
                :editable="editable"
                codeGroupCd="SOP_FIRE_MANAGEMENT_LEVEL_CD"
                label="방화관리 등급"
                name="sopFireManagementLevelCd" 
                v-model="performanceResultRecord.sopFireManagementLevelCd">
              </c-radio>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
              <c-text 
                :editable="editable"
                label="구조" 
                name="structure" 
                v-model="performanceResultRecord.structure" 
              />
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-card title="소방안전관리자" class="cardClassDetailForm">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-senior
                required
                :editable="editable"
                label="선임자"
                name="seniorStatusId"
                v-model="performanceResultRecord.seniorStatusId"
                @dataChange="changeSenior">
              </c-senior>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-text 
                disabled
                :editable="editable"
                label="직위" 
                name="seniorSpotName" 
                v-model="performanceResultRecord.seniorSpotName" 
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-datepicker 
                disabled
                :editable="editable"
                label="선임일" 
                name="seniorDate" 
                v-model="performanceResultRecord.seniorDate" 
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-text 
                disabled
                :editable="editable"
                label="자격증" 
                name="relatedCertificate" 
                v-model="performanceResultRecord.relatedCertificate" 
              />
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-card title="훈련" class="cardClassDetailForm">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
              <c-training
                required
                :editable="editable"
                label="비상대응훈련"
                name="sopEmergencyTrainingId"
                v-model="performanceResultRecord.sopEmergencyTrainingId"
                @dataChange="changeTraning">
              </c-training>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <c-text 
                disabled
                :editable="editable"
                label="훈련일정" 
                name="trainingPeriod" 
                v-model="performanceResultRecord.trainingPeriod" 
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
              <c-text 
                disabled
                :editable="editable"
                label="훈련교관" 
                name="trainingInstrunctors" 
                v-model="performanceResultRecord.trainingInstrunctors" 
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <c-text 
                disabled
                :editable="editable"
                label="훈련통제관" 
                name="trainingController" 
                v-model="performanceResultRecord.trainingController" 
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-textarea
                :rows="5"
                :editable="editable"
                label="훈련 실시사항" 
                name="trainingDetails" 
                v-model="performanceResultRecord.trainingDetails" 
              />
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-card title="교육" class="cardClassDetailForm">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
              <c-education
                required
                :editable="editable"
                label="교육"
                name="eduEducationId"
                v-model="performanceResultRecord.eduEducationId"
                @dataChange="changeEdu">
              </c-education>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <c-text 
                disabled
                :editable="editable"
                label="교육일정" 
                name="educationPeriod" 
                v-model="performanceResultRecord.educationPeriod" 
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
              <c-text 
                disabled
                :editable="editable"
                label="교육강사" 
                name="educationInstrunctors" 
                v-model="performanceResultRecord.educationInstrunctors" 
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-textarea
                :rows="5"
                :editable="editable"
                label="교육 실시사항" 
                name="educationDetails" 
                v-model="performanceResultRecord.educationDetails" 
              />
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fireFightingPerformanceResultDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingPerformanceResultRecordId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      performanceResultRecord: {
        sopFireFightingPerformanceResultRecordId: '',  // 소방 교육·훈련 실기결과기록부 일련번호
        plantCd: '',  // 사업장 코드
        recordName: '',  // 실기결과기록부명
        sopEmergencyTrainingId: '',  // 훈련 일련번호
        trainingName: '',
        eduEducationId: '',  // 교육 일련번호
        educationName: '',
        building: '',  // 소방대상물 - 상호
        sopFireManagementLevelCd: '',  // 소방대상물 - 방화관리 등급
        location: '',  // 소방대상물 - 소재지
        structure: '',  // 소방대상물 - 구조
        relatedUserName: '',  // 관계인 - 성명(기관 또는 법인명)
        address: '',  // 관계인 - 주소
        seniorStatusId: '',  // 소방안전관리자 선임 일련번호
        seniorUserId: '',  // 소방안전관리자 선임자ID
        seniorUserName: '',  // 소방안전관리자 선임자
        seniorSpotName: '',  // 소방안전관리자 선임 직위
        seniorDate: '',  // 소방안전관리자 선임일
        relatedCertificate: '',  // 소방안전관리자 자격증
        trainingController: '',  // 훈련 통제관
        eapTrainingTypeName: '',  // 훈련구분_정기/불시/소집/합동
        educationTypeName: '',  // 교육구분
        educationPeriod: '',  // 교육 날짜
        trainingPeriod: '',  // 훈련 날짜
        educationInstrunctors: '',  // 교육 강사
        trainingInstrunctors: '',  // 훈련 교관
        educationDetails: '',  // 교육 실시사항
        trainingDetails: '',  // 훈련 실시사항
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        instrunctors: [],
      },
      grid: {
        columns: [
          {
            name: 'userName',
            field: 'userName',
            label: '이름',
            style: 'width:150px',
            align: 'left',
            sortable: false,
          },
          // {
          //   name: "courseEvaluation",
          //   field: "courseEvaluation",
          //   label: "강평사항",
          //   style: 'width:250px',
          //   align: "left",
          //   type: 'textarea',
          //   sortable: false,
          // },
        ],
        height: '300px'
      },
      editable: true,
      isSave: false,
      saveType: 'POST',
      detailUrl: '',
      eduContentsListUrl: '',
      trainingContentsListUrl: '',
      saveUrl: '',
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: '',
        isFull: false,
        visible: false,
        width: '50%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.sopFireFightingPerformanceResultRecordId)
    },
    gridHeight() {
      let height = this.contentHeight - 200;
      if (height < 500) {
        height = 500;
      }
      return String(height) + 'px';
    }
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.fft.fireFightingPerformanceResult.get.url;
      this.eduContentsListUrl = selectConfig.sop.edu.result.firePerformanceResultRecord.contents.url;
      this.trainingContentsListUrl = selectConfig.sop.eap.training.firePerformanceResultRecord.contents.url;
      this.saveUrl = transactionConfig.sop.fft.fireFightingPerformanceResult.update.url;
      this.deleteUrl = transactionConfig.sop.fft.fireFightingPerformanceResult.delete.url;
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopFireFightingPerformanceResultRecordId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.performanceResultRecord, _result.data)
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      }
    },
    changeSenior(newVal, oldVal, data) {
      if (data) {
        this.$set(this.performanceResultRecord, 'seniorSpotName', data.seniorSpotName)
        this.$set(this.performanceResultRecord, 'seniorDate', data.seniorDate)
        this.$set(this.performanceResultRecord, 'relatedCertificate', data.relatedCertificate)
      } else {
        this.$set(this.performanceResultRecord, 'seniorSpotName', null)
        this.$set(this.performanceResultRecord, 'seniorDate', null)
        this.$set(this.performanceResultRecord, 'relatedCertificate', null)
      }
    },
    changeEdu(eduEducationId) {
      this.$http.url = this.eduContentsListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        eduEducationId: eduEducationId
      };
      this.$http.request((_result) => {
        this.$_.extend(this.performanceResultRecord, {
          educationName: _result.data.educationName,
          educationTypeName: _result.data.educationTypeName,
          educationPeriod: _result.data.educationDate,
          educationDetails: _result.data.educationDetails,
          educationInstrunctors: this.$_.join(this.$_.map(_result.data.instrunctors, 'userName'), ', ')
        })
        // 기존 교육강사는 제거 후 신규 교육 문서에 따른 강사 삽입
        this.performanceResultRecord.instrunctors = this.$_.reject(this.performanceResultRecord.instrunctors, { trainingFlag: 'N' })
        this.performanceResultRecord.instrunctors = this.$_.concat(this.performanceResultRecord.instrunctors, this.$_.map(_result.data.instrunctors, instrunctor => {
          return this.$_.extend(instrunctor, {
            editFlag: 'C',
            regUserId: this.$store.getters.user.userId
          })
        }))
      },);
    },
    changeTraning(sopEmergencyTrainingId) {
      this.$http.url = this.trainingContentsListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        sopEmergencyTrainingId: sopEmergencyTrainingId
      };
      this.$http.request((_result) => {
        this.$_.extend(this.performanceResultRecord, {
          trainingName: _result.data.trainingName,
          eapTrainingTypeName: _result.data.eapTrainingTypeName,
          trainingPeriod: _result.data.trainingDate,
          trainingDetails: _result.data.trainingDetails,
          trainingController: _result.data.trainingController,
          trainingInstrunctors: this.$_.join(this.$_.map(_result.data.instrunctors, 'userName'), ', ')
        })
        // 기존 훈련교관은 제거 후 신규 비상대응훈련 문서에 따른 교관 삽입
        this.performanceResultRecord.instrunctors = this.$_.reject(this.performanceResultRecord.instrunctors, { trainingFlag: 'Y' })
        this.performanceResultRecord.instrunctors = this.$_.concat(this.performanceResultRecord.instrunctors, this.$_.map(_result.data.instrunctors, instrunctor => {
          return this.$_.extend(instrunctor, {
            editFlag: 'C',
            regUserId: this.$store.getters.user.userId
          })
        }))
      },);
    },
    savePerformanceResultRecord() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.sop.fft.fireFightingPerformanceResult.update.url;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.fft.fireFightingPerformanceResult.insert.url;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.performanceResultRecord.regUserId = this.$store.getters.user.userId
              this.performanceResultRecord.chgUserId = this.$store.getters.user.userId
              
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    savePerformanceResultRecordCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.sopFireFightingPerformanceResultRecordId = result.data
      this.getDetail();
    },
    deleteperformanceResultRecord() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.sopFireFightingPerformanceResultRecordId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });

    },
  }
};
</script>
